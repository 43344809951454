@import 'src/styles/normalize.scss';
@import 'src/styles/fonts.scss';
@import 'src/styles/mixins.scss';

body {
  width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: var(--white);
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  h1 {
    @include typographyElement(26px, 30px, 30px, 34px, 34px, 38px);
    font-family: var(--font-secondary);
    color: var(--color-primary);
  }

  h2 {
    @include typographyElement(24px, 28px, 26px, 30px, 30px, 34px);
    font-family: var(--font-secondary);
    color: var(--color-primary);
  }

  h3 {
    @include typographyElement(18px, 22px, 20px, 24px, 22px, 26px);
    font-family: var(--font-primary);
    color: var(--black);
  }

  h4 {
    font-family: var(--font-primary);
    color: var(--black);
  }

  p {
    font-family: var(--font-primary);
    color: var(--black);
  }
  a {
    font-family: var(--font-primary);
  }
}
