@import 'src/styles/mixins';

.container {
  height: auto;
  min-height: calc(100vh - 164px);

  &__content {
    height: 100%;
  }
  &__marginBottom {
    margin-bottom: 50px;
  }
}
