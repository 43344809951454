:root {
  --black: #000000;
  --white: #ffffff;
  --success: #34c759;
  --error: #ff3b30;
  --error-light: #ffebea;
  --orange: #ff9d00;
  --gray-primary: #f2f2f2;
  --gray-secondary: #b9b8b6;
  --gray-tertiary: #cccccc;
  --gray-dark: #2d2d2d;
  --transparent: transparent;
  --brown: #A37441;
  --pink: #FC6DCC;
  --olive-green: #79894C;

  // Font weight
  --font-weight-light: 200;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;

  --font-primary: 'Manrope', sans-serif;
  --font-secondary: 'Manrope', sans-serif;
  --header-background-color: #080ce2;
  --footer-background-color: #00238e;
  --color-title: #080ce2;
  --color-primary: #080ce2;
  --color-primary-rgb: 008, 012, 226;
  --color-secondary: #009aff;
  --color-loader-primary: #080ce2;
  --color-loader-secondary: #009aff;
}
