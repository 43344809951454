.lds_ripple {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;

  div {
    position: absolute;
    border: 2px solid var(--color-primary);
    opacity: 1;
    border-radius: 50%;
    animation: lds_ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
  
}
@keyframes lds_ripple {
  0% {
    top: 9px;
    left: 9px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 9px;
    left: 9px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 9px;
    left: 9px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 17px;
    height: 17px;
    opacity: 0;
  }
}