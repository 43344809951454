/* montserrat-200-light - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: local(''),
    url('../fonts/montserrat-v23-latin/montserrat-v23-latin-200.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/montserrat-v23-latin/montserrat-v23-latin-200.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-400-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../fonts/montserrat-v23-latin/montserrat-v23-latin-400.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/montserrat-v23-latin/montserrat-v23-latin-400.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-600-semibold - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('../fonts/montserrat-v23-latin/montserrat-v23-latin-600.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../fonts/montserrat-v23-latin/montserrat-v23-latin-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* everett light - latin */
@font-face {
  font-family: 'Everett';
  font-style: normal;
  font-weight: 200;
  src: local(''), url('../fonts/everett/everett-Light.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

/* everett regular - latin */
@font-face {
  font-family: 'Everett';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/everett/everett-Regular.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

/* everett semi-bold - latin */
@font-face {
  font-family: 'Everett';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../fonts/everett/everett-Medium.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

/* everett bold - latin */
@font-face {
  font-family: 'Everett';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../fonts/everett/everett-Bold.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

/* DINPro - Regular */
@font-face {
  font-family: 'DIN Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/DINPro/DINPro.woff2') format('woff2'),
    url('../fonts/DINPro/DINPro.woff') format('woff');
}

/* DINPro - Bold */
@font-face {
  font-family: 'DIN Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../fonts/DINPro/DINPro-Bold.woff2') format('woff2'),
    url('../fonts/DINPro/DINPro-Bold.woff') format('woff');
}

/* DINPro - Medium */
@font-face {
  font-family: 'DIN Pro';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../fonts/DINPro/DINPro-Medium.woff2') format('woff2'),
    url('../fonts/DINPro/DINPro-Medium.woff') format('woff');
}

/* Manrope-400-regular - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/manrope/manrope-regular.woff2') format('woff2'),
       url('../fonts/manrope/manrope-regular.woff') format('woff');
}

/* Manrope-700-bold - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/manrope/manrope-bold.woff2') format('woff2'),
       url('../fonts/manrope/manrope-bold.woff') format('woff');
}

/* Manrope-800-extraBold - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('../fonts/manrope/manrope-extraBold.woff2') format('woff2'),
       url('../fonts/manrope/manrope-extraBold.woff') format('woff');
}

/* Sella-stencil-bold - latin */
@font-face {
  font-family: 'SellaStencil';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/sella-stencil/sella-stencil.woff2') format('woff2'),
       url('../fonts/sella-stencil/sella-stencil.woff') format('woff');
}
