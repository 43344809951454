@import 'src/styles/include-media';

.container {
  display: flex;
  box-sizing: border-box;
  width: 100%;

  @include media('<tablet') {
    width: 100%;
  }

  &.column {
    flex-direction: column;
    h1 {
      font-family: var(--font-primary);
      font-weight: var(--font-weight-extrabold);
    }
    h2 {
      margin-top: 10px;
      font-family: var(--font-primary);
      font-weight: var(--font-weight-extrabold);
    }
  }

  &.row {
    flex-direction: row;
  }

  &.rowReverse {
    flex-direction: row-reverse;
  }

  &.alignCenter {
    align-items: center;
  }

  &.alignStart {
    align-items: flex-start;
  }

  &.alignEnd {
    align-items: flex-end;
  }

  &.alignBaseline {
    align-items: baseline;
  }

  &.justifyCenter {
    justify-content: center;
  }

  &.justifyStart {
    justify-content: flex-start;
  }

  &.justifyEnd {
    justify-content: flex-end;
  }

  &.justifyBaseline {
    justify-content: baseline;
  }

  &.justifyBetween {
    justify-content: space-between;
  }

  &.justifyAround {
    justify-content: space-around;
  }
}
