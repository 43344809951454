@import 'src/styles/mixins';

.logo {
  &.black {
    fill: var(--black);
  }
  &.white {
    fill: var(--white);
  }
  &.gradient {
    fill: inherit
  }
  &.faicreditLogo{
    max-height: 20px;
    @include media('<desktop') {
      max-height: 15px;
    }
  }
  &.logoSella {
    max-height: 40px;
    @include media('<desktop') {
      max-height: 25px;
    }
  }
}
.powered {
  font-size: 12px;
  color: var(--white);
  text-align: center;
  text-decoration: none;
  padding: 0 10px;
}
.link__wrapper {
  grid-area: logo;
  display: inline-flex;
  align-items: baseline;
}

