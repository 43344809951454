@import 'src/styles/mixins';

.container {
  background-color: var(--header-background-color);

  &__fic {
    background-color: var(--white) !important;
  }

  &__header {
    box-sizing: border-box;
    height: 80px;
    display: grid;
    grid-template-columns: auto;
    grid-template-areas: 'logo navbar';
    align-items: center;
    padding: 0 20px;
    @include layoutElementContentWidth;
    @include media('<desktop') {
      grid-template-columns: auto 65px;
      grid-template-areas: 'logo hamburger';
      padding: 10px 0 0 20px;
    }
    @include media('>=desktop-xxl') {
      padding: 10px 0;
    }

    .navbar {
      justify-content: flex-end;
      grid-area: navbar;
      @include media('<desktop') {
        position: absolute;
        right: 0;
        top: 80px;
        background-color: var(--color-primary);
        width: 0;
        height: calc(100vh - 60px);
        overflow: hidden;
        z-index: 999;
        display: flex;
        flex-direction: column;
        margin: 0;
        justify-content: center;
        gap: 10px 0;
        transition: all 0.2s ease-in;
      }

      a {
        &.textLink {
          align-items: center;
          font-size: 16px;
          line-height: 22px;
          font-weight: var(--font-weight-regular);
          margin-left: 50px;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          @include media('<tablet') {
            margin-left: 0;
            width: 100%;
          }
          span {
            @include media('<desktop') {
              margin: 0 auto;
              padding: 10px;
            }
          }
        }
      }

      &.active {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 0;
        justify-content: center;
        gap: 10px 0;
        @include media('>=desktop') {
          display: inline-flex;
          flex-direction: row;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }

    .hamburger {
      grid-area: hamburger;
      display: none;
      background-color: transparent;
      border: none;
      padding: 10px;
      width: 60px;
      min-width: 60px;
      height: 60px;
      margin: 0 0 0 auto;
      @include media('<desktop') {
        display: block;
      }
      svg {
        width: 24px;
        height: 24px;
        fill: var(--white);
      }
    }
  }
}
