@import 'src/styles/mixins';

.container {
  background-color: var(--white);
  width: 100%;
  max-width: 1280px;
  margin: 40px auto;
  position: relative;
  border-radius: 0;

  @include media('>=tablet') {
    padding: 0 20px;
  }

  @include media('<tablet') {
    margin: 0 auto;
    padding: 40px 20px;
  }

  &.loginGrid {
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'login' 'caption';
    margin: 0 auto;
    padding: 0;
    border-radius: 0;
    height: 100%;
    @include layoutElementContentWidth;

    &:before {
      display: none;
    }

    @include media('<tablet') {
      align-items: flex-start;
    }
    @include media('>=desktop') {
      min-height: calc(100vh - 180px);
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'caption login';
    }

    .container__paperContent {
      padding: 30px;
    }
  }

  &.dashboardGrid {
    .container__paperContent {
      @include media('>desktop') {
        max-width: 100%;
      }
    }
  }

  &__paperContent {
    @include media('>desktop') {
      max-width: 940px;
    }

    h1,
    h2 {
      position: relative;
    }

    h3 {
      font-weight: var(--font-weight-light);
      strong {
        font-weight: var(--font-weight-bold);
      }
      margin-bottom: 30px;
    }

    .note {
      font-weight: var(--font-weight-bold);
      font-size: 14px;
      margin-top: 30px;
      position: relative;
      padding-left: 10px;
      &:before {
        content: '';
        transform: rotate(-90deg);
        margin-left: -8px;
        width: 16px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 6px;
      }
    }
  }

  &__caption {
    grid-area: caption;
    width: 100%;
    height: 100%;
    padding: 30px 30px 0 30px;
    display: grid;
    align-items: center;

    @include media('<tablet') {
      background-color: var(--color-primary);
      grid-template-columns: 1fr;
      grid-template-areas: 'caption' 'visual';
    }
    @include media('>=tablet', '<desktop') {
      background-color: var(--color-primary);
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'caption visual';
      padding: 70px 30px 0 30px;
    }
    @include media('>=desktop') {
      padding: 0 30px 0 0;
      grid-template-columns: 1fr;
      grid-template-areas: 'caption' 'visual';
    }
    @include media('>=desktop-xxxl') {
      padding: 30px 30px 0 0;
    }

    h1,
    h2 {
      margin-top: 0;
      color: var(--white);
    }

    h2 {
      font-size: 22px;
    }
  }

  &__visual {
    width: 80%;
    margin: 0 auto;
    align-self: end;
    @include media('<tablet') {
      margin: 30px auto 0 auto;
      display: flex;
    }
  }

  &.success {
    button:last-child {
      background-color: var(--success);
    }

    h1,
    h2 {
      color: var(--success);
    }

    svg {
      &.icon {
        height: 30px;
        margin: 0 auto 10px 0;
        path {
          fill: var(--success);
        }
      }
    }

    .note {
      &:before {
        background-image: linear-gradient(
          to right,
          var(--success),
          var(--color-primary)
        );
      }
    }
  }

  &.error {
    button {
      background-color: var(--error);
    }

    h1,
    h2 {
      color: var(--error);
    }

    svg {
      &.icon {
        height: 30px;
        margin: 0 auto 10px 0;
        path {
          fill: var(--error);
        }
      }
    }
  }

  &__loader {
    margin: 80px auto;
  }
}
