@import 'src/styles/mixins';

.container {
  background-color: var(--footer-background-color);

  &__footer {
    font-size: 12px;
    line-height: 18px;
    font-family: var(--font-primary);
    align-items: center;
    @include layoutElementContentWidth;

    @include media('<tablet') {
      display: grid;
      padding: 20px;
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-areas:
        'logo logo'
        'address address'
        'privacy cookies';
    }

    @include media('>=tablet') {
      padding: 20px;
      display: grid;
      grid-template-columns: 300px auto;
      grid-template-rows: auto;
      grid-template-areas:
        'logo logo logo address address address'
        'logo logo logo privacy cookies .';
    }

    p {
      color: var(--white);
      @include media('<tablet') {
        margin: 15px 0px;
      }
    }
  }

  &__privacy {
    grid-area: privacy;
  }

  &__cookies {
    grid-area: cookies;
  }

  &__address {
    grid-area: address;
    font-weight: var(--font-weight-regular);
    text-align: left;
    margin: 0;
  }

  &__cookies,
  &__privacy {
    text-decoration: underline;
    color: var(--white);
    font-weight: var(--font-weight-regular);
    &:hover {
      text-decoration: none;
    }
    @include media('>=tablet') {
      margin: 0px 30px 0 0;
    }
  }
}
