@import 'src/styles/breakpoints.scss';

@mixin defaultShadow {
  box-shadow: 0 0 4px 0 rgba(9, 3, 22, 0.15);
}

@mixin layoutElementPadding {
  @include media('<=phone') {
    padding: 15px 25px;
  }
  @include media('>phone', '<=tablet') {
    padding: 20px;
  }
  @include media('>tablet', '<=desktop') {
    padding: 25px 40px;
  }
  @include media('>desktop') {
    padding: 25px 0 25px 0;
  }
}

@mixin layoutElementContentWidth {
  @include media('<desktop') {
    width: 100%;
  }
  @include media('>=desktop') {
    width: 1280px;
  }
}

@mixin typographyElement(
  $smallFontSize,
  $smallLineHeight,
  $mediumFontSize,
  $mediumLineHeight,
  $largeFontSize,
  $largeLineHeight
) {
  font-size: $smallFontSize;
  line-height: $mediumLineHeight;

  @include media('<desktop') {
    font-size: $smallFontSize;
    line-height: $mediumLineHeight;
  }
  @include media('>=desktop', '<desktop-xxxl') {
    font-size: $mediumFontSize;
    line-height: $largeLineHeight;
  }
  @include media('>=desktop-xxxl') {
    font-size: $largeFontSize;
    line-height: $largeLineHeight;
  }
}

@mixin animationFadeInDown {
  animation-duration: 500ms;
  animation-fill-mode: both;
  animation-name: fadeInDown;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }
}

@mixin chevronRotated {
  &.expanded {
    svg {
      transition: all 250ms ease;
      transform: rotate(180deg);
    }
  }
}