@import 'src/styles/include-media';

.buttons {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'cancel submit';
  @include media('<tablet') {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'sumbit'
      'cancel';
  }
  .button {
    width: auto;
    margin: 0 auto;
    @include media('<tablet') {
      margin: 0 auto 20px auto;
    }
  }
}